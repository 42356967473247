<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row justify="start">
            <v-col
                    cols="2"
                    v-for="division in divisions"
                    v-bind:key="division.divisionID"
                    class="viewCard"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <router-link :to="{ name: 'SurveyProvinceDivisionProjects', params: { provinceId: division.provinceID, divisionId: division.divisionID } }">
                            <HoverCard :cardData="division">
                            </HoverCard>
                        </router-link>
                    </template>
                    <span>{{division.divisionName}}</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "ProvinceList",
        data() {
            return {
                items: [],
                divisions: [],
            }
        },
        mounted() {
            this.getProvince()
        },
        components: {
            HoverCard
        },
        methods: {
            getProvince: async function () {
                var divNepali = localStorage.getItem('lang');
                if (divNepali == 'ne') {
                    try {
                        const response = await axios.get(
                            "Division/GetDivisionListNepaliByProvinceId/" + this.$route.params.provinceId
                        );
                        const icons = ["mdi-image-filter-hdr", "mdi-airport", "mdi-home-silo", "mdi-bed-outline", "mdi-account-arrow-left-outline", "mdi-store", "mdi-car-brake-parking", "mdi-temple-buddhist-outline", "mdi-account-cowboy-hat-outline", "mdi-google-street-view", "mdi-car-convertible", "mdi-airplane", "mdi-town-hall", "mdi-airplane", "mdi-cash-multiple", "mdi-image-filter-hdr", "mdi-book-open", "mdi-walk"]
                        this.divisions = response.data.divisionList.map((res, index) => {
                            res.icon = icons[index]
                            res.name = res.divisionName
                            return res
                        })
                        this.items = [
                            {
                                text: this.$t('dashboard'),
                                disabled: false,
                                to: "/SurveyDashboard",
                                exact: true
                            },
                            {
                                text: response.data.provinceName,
                                disabled: true,
                                href: "Division/1"
                            }
                        ];
                    } catch (e) {
                        if (e.response.data.State === "AccessTokenExpired") {
                            this.apiResponse(e);
                            this.loadItems();
                        }
                    }
                }
                else {
                    try {
                        const response = await axios.get(
                            "Division/GetDivisionListByProvinceId/" + this.$route.params.provinceId
                        );
                        const icons = ["mdi-image-filter-hdr", "mdi-airport", "mdi-home-silo", "mdi-bed-outline", "mdi-account-arrow-left-outline", "mdi-store", "mdi-car-brake-parking", "mdi-temple-buddhist-outline", "mdi-account-cowboy-hat-outline", "mdi-google-street-view", "mdi-car-convertible", "mdi-airplane", "mdi-town-hall", "mdi-airplane", "mdi-cash-multiple", "mdi-image-filter-hdr", "mdi-book-open", "mdi-walk"]
                        this.divisions = response.data.divisionList.map((res, index) => {
                            res.icon = icons[index]
                            res.name = res.divisionName
                            return res
                        })
                        this.items = [
                            {
                                text: "Dashboard",
                                disabled: false,
                                to: "/SurveyDashboard",
                                exact: true
                            },
                            {
                                text: response.data.provinceName,
                                disabled: true,
                                href: "Division/1"
                            }
                        ];
                    } catch (e) {
                        if (e.response.data.State === "AccessTokenExpired") {
                            this.apiResponse(e);
                            this.loadItems();
                        }
                    }
                }
                
            }
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>